import React from "react";

export default function MainFooter() {
    return(
        <footer className="main-footer centered">
            <p>Musiques en Thau</p>
        </footer>

    );
}


//Video by Paul McCann. Site developed and maintained by Therese Bjørknes.